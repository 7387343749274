import React from "react";
import { Navigate } from "react-big-calendar";
import { Box, Button, Text, Flex, Image , Avatar} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { IoChevronForwardOutline } from "react-icons/io5";
import { USER_IMAGE_URL } from "../../apiPaths";
import moment from "moment";
const CustomToolbar = ({ label, onNavigate, onView, view, profile, displayName,currentUser }) => {
  const image = USER_IMAGE_URL+"/"+profile;
  const [month, year] = label.split(" ");
  const goToBack = () => {
    onNavigate(Navigate.PREVIOUS);
  };

  const goToNext = () => {
    onNavigate(Navigate.NEXT);
  };

  const goToCurrent = () => {
    onNavigate(Navigate.TODAY);
  };

  const changeView = (view) => {
    onView(view);
  };
  const parsedDate = moment(label, "dddd MMM DD");
  const fullMonthName = parsedDate.format("MMMM");
  const fullDayName = parsedDate.format("dddd");

  return (
    <Box className="rbc-toolbar" py="5" px="5" display="flex" m="0"
     justifyContent="space-between" background="black" color="white" >
      <Box>
        {view === "day" && (
          <Box
            fontSize="22px"
            fontWeight="bold"
            cursor="pointer"
            onClick={() => changeView("month")}>
            {fullMonthName}
          </Box>
        )}
        {view === "month" ? (
          <Box
            display="flex"
            justifyContent="center"
            gap="5px"
            minW={{ base: "auto", md: "auto" }}>
            <Text
              as="span"
              fontSize={{ base: "16px", md: "32px" }}
              fontWeight="bold">
              {month}
            </Text>
            <Text as="span" fontSize={{ base: "16px", md: "32px" }}>
              {year}
            </Text>
          </Box>
        ) : view === "week" ? (
          <Box
            minW={{ base: "auto", md: "250px" }}
            fontSize={{ base: "12px", md: "18px" }}
            fontWeight="bold">
            {label}
          </Box>
        ) : (
          <Box display="flex" gap="15px" alignItems="center" color="white"  >
            <Button type="button" onClick={goToBack} boxShadow="md">
              <IoIosArrowBack size={20} />
            </Button>
            <Box fontSize={{ base: "12px", md: "18px" }}>{fullDayName}</Box>
            <Button type="button" onClick={goToNext} boxShadow="md">
              <IoChevronForwardOutline size={20} />
            </Button>
          </Box>
        )}

        {view !== "day" && (
          <Box display="flex" gap="10px">
            <Button type="button" onClick={goToBack} boxShadow="lg">
              <IoIosArrowBack size={20} />
            </Button>
            <Button type="button" onClick={goToNext} boxShadow="lg">
              <IoChevronForwardOutline size={20} />
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" style={{padding:"0px 10px", color:"white"}}>
        {image ? (
          <Image 
            width="50px"
            height="50px"
            borderRadius="50%"
            src={image}
            alt={displayName}
            crossOrigin="anonymous"
          />
        ) : (
          <Avatar
            width="50px" 
            height="50px"
            name={displayName} 
            src={
             currentUser?.profile
                ? USER_IMAGE_URL +  currentUser.profile
                : "https://avatars2.githubusercontent.com/u/37842853?v=4"
            }
            cursor="pointer"
            crossOrigin="anonymous"
          />
          
        )}
        <Text fontWeight="700" fontSize="18px" color="white" mx="10px">
          {displayName}
        </Text>
      </Box>
      <Box>
        {view !== "day" ? (
          <Box bg="#F2F2F2" p="1" rounded="20px">
            <Button
              onClick={() => changeView("month")}
              isActive={view === "month"}
              _active={{
                bg: "black",
                color: "white",
              }}>
              Month
            </Button>
            <Button
              onClick={() => changeView("week")}
              isActive={view === "week"}
              _active={{
                bg: "black",
                color: "white",
              }}>
              Week
            </Button>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomToolbar;
