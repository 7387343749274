import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export default function MyBookingHeader({ title, des }) {
  return (
    <>
      <Box
        w="full"
        px="24px"
        bg={{ base: "#1D1D1D", md: "#1D1D1D" }}
        color="white"
        py={{ base: "0px", md: "24px" }}>
        <Flex
          alignItems="center"
          gap="5px"
          flexDir="column"
          display={{ base: "none", md: "flex" }}>
          <Heading color="white" fontWeight="600" fontSize="28px">
            {title}
          </Heading>
          <Text color="white" fontWeight="500" fontSize="14px">
            {des}
          </Text>
        </Flex>
      </Box>

      <Link>
        <Box
          display={{ base: "block", md: "none" }}
          w="full"
          px="24px"
          borderBottomWidth="1px"
          bg="dull_black"
          boxShadow="sm"
          color="white"
          py="6px">
          Back
        </Box>
      </Link>
    </>
  );
}
