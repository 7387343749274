import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import { FaLocationArrow, FaRegCalendar } from "react-icons/fa6";
import { SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../../../apiPaths";
import StdProfile from "../../../ProfileDetails";
export default function MyBookingsTutorList({data, onModifyBooking, bookingId}) {
  const booking = data;    
  const currentDate = new Date();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [isBookingCompleted, setIsBookingCompleted] = useState(false); 
  const startDate = new Date(booking?.sessionId?.start);
  const endDate = new Date(booking?.sessionId?.end);
  const formattedDate = startDate.toLocaleDateString("en-GB");
  const startTime = startDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  const endTime = endDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  const formattedTime = `${startTime} - ${endTime}`;

  let buttonLabel = ""; // Default
  if (startDate > currentDate) {
    buttonLabel = "Upcoming"; // Future
  } else if (
    startDate.toDateString() === currentDate.toDateString() &&
    endDate >= currentDate
  ) {
    buttonLabel = "Today"; // Today
  } else if (endDate < currentDate) {
    buttonLabel = "Completed"; // Past
  }

  useEffect(() => {
    if (endDate < currentDate) {
      setIsBookingCompleted(true);
    }
  }, [endDate, currentDate]);

  const image = SERVICE_IMAGE_URL + booking?.serviceId?.image;
  const details = currentUser.roleName == "STUDENT" ? booking?.tutorId : booking?.userId; 
  const [showDetailModel, setShowDetailModel] = useState(false);

  function handleFormOpen(val) {    
    setShowDetailModel(val);
  }

  function handleFormClose(val) {
    setShowDetailModel(val);
  }

  function handleModifyBooking(id) {
    onModifyBooking(id);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
  <Box flexDirection={{ base: "column", lg: "row" }}
  alignItems="center"
  w="100%"
  bg="black"
  rounded="8px"
  gap="30px"
  boxShadow="md"
  p="16px">
    <Box
    width={{ base: "100%", lg: "auto" }}
    color="white"
    rounded="8px"
  >
    <Image
      height="250px"
      width="100%"
      borderRadius="8px"
      objectFit="cover"
      rounded={2}
      src={image}
      crossOrigin="anonymous"
      alt="Booking Image"
    />
  </Box>
  <Flex>
  
  <Flex
    flex="1"
    justifyContent="space-between"
    alignItems="center"
    flexDirection={{ base: "column", lg: "row" }}
    gap={{ base: "10px", lg: "20px" }}
    p="10px"
  >
    <Flex flexDirection="column" gap="8px">
      <Box fontSize="18px" fontWeight="700" color="#fff" pb="4px">
        {booking?.serviceId?.name}
      </Box>

      <Flex flexDirection="row" alignItems="center" gap="8px" pb="10px">
        <Image
          height="32px"
          width="32px"
          borderRadius="50%"
          src={
            booking?.userId?.profilePic
              ? USER_IMAGE_URL + booking?.userId?.profilePic
              : "https://avatars2.githubusercontent.com/u/37842853?v=4"
          }
          crossOrigin="anonymous"
          alt="Profile Image"
          onClick={() => handleFormOpen(true)}
          cursor="pointer"
        />
        <Box fontSize="15px" fontWeight="600" color="white">
          {booking?.userId?.firstName} {booking?.userId?.lastName}
        </Box>
      </Flex>

      <Box ml="4px" >
        <Flex alignItems="center" gap="10px" fontSize="14px" color="white" pb="4px">
          <FaRegCalendar /> {formattedDate}
        </Flex>
        <Flex alignItems="center" gap="10px" fontSize="14px" color="white" pb="4px">
          <TimeIcon /> {formattedTime}
        </Flex>
        <Flex alignItems="center" gap="10px" fontSize="14px" color="white" pb="4px">
          <FaLocationArrow /> {booking?.sessionId?.sessionLocation}
        </Flex>
      </Box>
    </Flex>
   
    
  </Flex>
</Flex>
<Flex 
          justifyContent={!isBookingCompleted ? "center" : ""} 
          marginLeft={isBookingCompleted ? "10px" : ""}
          alignItems="center"
        >
<Flex
        flexDir="row"
        gap={{ base: "8px", lg: "12px" }}
        mb={{ base: "30px", lg: "0px" }}
        w="fit-content"
      >
        <Button
          color="white"
          bg="#0033ff"
          _hover={{
            bg: "#0033ff"
          }}
          rounded="5px"
          py="12px"
          px="20px"
          fontSize="14px"
          fontWeight="600"
          cursor="default"
        >
          {buttonLabel}
        </Button>
        { !isBookingCompleted && (
         <Button
            bg="#fff"
            color="black"
            _hover={{
              bg: "white",
              border: "1px solid black",
            }}
            border="1px solid white"
            rounded="5px"
            py="12px"
            px="40px"
            w={{ base: "100%", lg: "auto" }}
            fontSize="14px"
            fontWeight="600"
            onClick={() => handleModifyBooking(booking._id)}
          >
            Modify
          </Button>
       )}
      </Flex>
</Flex>
  </Box>
    

      {showDetailModel && (
        <StdProfile onFormClose={handleFormClose} details={details} />
      )}
    </>
  );
}
