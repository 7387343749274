import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function ServiceHeader({ closeForm, eventDate }) {
  const location = useLocation();
  const { pathname } = location;

  const closeModel = function() {
    closeForm(false);
  }
  return (
    <>
      <Box
        w="full"
        px={{ base: "5px", xl: "15px", "2xl": "24px" }}
        color="black">
        <Box>
          <Link
            to={`/calendar/tutor/${pathname.split("/")[3]}`}
            onClick={closeModel}>
            <Text fontWeight="500" fontSize={"16px"} color={"#000"}>
              Cancel
            </Text>
          </Link>
        </Box>

        <Flex
          flexDir="column"
          display={"flex"}
          justifyContent={"center"}
          alignItems="center">
          <Heading
            color="black"
            fontWeight="600"
            fontSize={{ base: "18px", sm: "24px" }}>
            Choose a service
          </Heading>

          <Text
            color="text"
            fontWeight="500"
            fontSize={{ base: "12px", sm: "14px" }}
            mt={{ base: 0, sm: 2 }}>
            { eventDate }
          </Text>
          {/* <Text
            color="text"
            fontWeight="500"
            fontSize={{ base: "12px", sm: "14px" }}
            mt={{ base: 0, sm: 2 }}>
            £80.00 p/h
          </Text> */}
        </Flex>
      </Box>
    </>
  );
}
