import React, { useState, useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  isSaturday,
  isSunday,
} from "date-fns";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { enGB } from "date-fns/locale";
import CustomToolbar from "../CustomToolbar";
import CustomDayHeader from "../weekview_header";
import EventModal from "../EventModal";
import EditBooking from "../../FromSidebar/Shared/EditBooking";
import "../calender.css";
import { Box, Tooltip, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, Button, ModalFooter, useDisclosure } from "@chakra-ui/react";
import CalenderHeader from "../../Headers/CalenderHeader";
import { API_BASE_URL } from "../../../apiPaths";
import RequestForm from "../../Forms/MyRequest/RequestForm";
import RequestActionModal from "../RequestActionModal";
import BookSessionFormTwo from "../../Forms/BookSession/BookSessionFormTwo";
import BookSessionFormOne from "../../Forms/BookSession/Service/BookSessionFormOne";
; 
const locales = {
  "en-GB": enGB,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: enGB }),
  getDay,
  locales,
});

const StdCalendar = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { tutorId } = useParams();
  const location = useLocation();
  const [view, setView] = useState(() => {
    return localStorage.getItem("view") || "month";
  });
  const [tutor, setTutor] = useState({});
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRequestActionModal, setShowRequestActionModal] = useState(false);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date);
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const sessionRequestFormData = JSON.parse(localStorage.getItem('sessionRequestFormData'));
  const [cancelBookingSession, setCancelBookingSession] = useState(false);
  const [isEditBooking, setIsEditBooking] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [minTime, setMinTime] = useState();
  const [maxTime, setmaxTime] = useState();
  const [showBookSessionForm, setSHowBookedSessionForm] = useState(false);
  const [showChooseServiceForm, setSHowChooseServiceForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [chooseServiceData, setChooseServiceData] = useState({});

  const params = new URLSearchParams(location.search);
  const isConfirm = params.get('isconfirm') === 'true';
  const isModify = params.get('ismodify') === 'true';
  const isCancel = params.get('iscancel') === 'true';
  const isEmail = params.get('isemail') === 'true';  
  const requestID = params.get('rid'); 

  function handleFormClose(val) {
    setIsEditBooking(val);
  }

  const handleViewChange = (view) => {
    localStorage.setItem("view", view);
    setView(view);
  };

  const formatTime = (dateString) => {
    return format(new Date(dateString), 'HH:mm');
  };

  const searchParams = new URLSearchParams(location.search);
  const tid = searchParams.get("tid");

  // Custom day cell style getter
  // const dayPropGetter = (date) => {
    // const dayOfWeek = getDay(date);
    // const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    // if (isWeekend) {
    //   return {
    //     style: {
    //       backgroundColor: "#f0f0f0",
    //     },
    //   };
    // }
    // return {};
  // };

  const dayPropGetter = (date) => {
    const today = new Date();
    const isPast = date < today;

    if (date.toDateString() === today.toDateString()) {
      return {
        style: {
          backgroundColor: '#252525',
          borderRadius: '10px',
        }
      };
    } else if (isPast) {
      return {
        style: {
         backgroundColor: '#000',
         borderRadius: '10px',
         border: 'none',
         margin: '2px',
         color: 'grey'
        }
      };
    } else {
          return {
            style: {
              backgroundColor: '#252525',
              borderRadius: '10px',
              border: 'none',
              margin: '2px',
            }
          };
        }
  };

  const eventPropGetter = (event) => {
    const isNarrowScreen = window.innerWidth <= 600;
    let newStyle = {
      color: "black",
            borderRadius: isNarrowScreen ? "20px" : "5px",
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            border: "2px solid black",
            color: "white",
    };

    if (event.isBooked === "y") {
      const today = new Date();
      if (new Date(event.end) < today) newStyle.background = "#002299";
      else newStyle.background = "#0033ff";
    } else {
      newStyle.background = "#0033ff";
    }

    return {
      style: newStyle,
    };
  };

  const handleCloseModal = (val) => {
    setShowModal(val);
  };

  function formatEventDate(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Options for formatting
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = startDate.toLocaleDateString('en-GB', options); // Format: Tue 10 Oct 2023
    const startTime = startDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Format: 10:00
    const endTime = endDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Format: 11:00

    return `${formattedDate}, ${startTime} - ${endTime}`;
}

  const handleEventSelect = (event) => {
    console.log(event);
    
    const formattedString = formatEventDate(event.start, event.end);
    const selectedDateStart = new Date(event.start);
    const today = new Date();
    const isPast = selectedDateStart < today;
    if (event.isBooked == "y" && isPast) return;
    if(event?.isBooked == "y") {     
      event.isCalendarBooking = true; 
      setSelectedBooking(event);
      setIsEditBooking(true);
      return;
    }
    localStorage.setItem("selectedTutorId", tutorId);
    if (event?.serviceId?.name === "One to One Session") {
      setSHowChooseServiceForm(true);
      setChooseServiceData({
        sessionId: event._id,
        tutorId : event.userId,
        sessionDate: formattedString
      })
      // navigate(`/tutor/choose-service/${event.userId}/${formattedString}`);
    }
    else {
      setSelectedEvent(event)
      setSHowBookedSessionForm(true);
      // navigate(`/book-session/${event?.serviceId?._id}?type=n&session=${event?._id}`);
    }
  };

  const handleBookingClose = (val) => {
    setSHowBookedSessionForm(val);
  }

  const handleCloseChooseServiceForm = (val) => {
    setSHowChooseServiceForm(val);
  }

  // setSelectedEvent(null);

  //   const selectedDateStart = new Date(slotInfo.start);
  //   const startOfWorkday = new Date(selectedDateStart.setHours(9, 0, 0, 0));
  //   const endOfWorkday = new Date(selectedDateStart.setHours(17, 0, 0, 0));

  //   setSelectedSlot({ start: startOfWorkday, end: endOfWorkday });
  //   setShowModal(true);

  // const handleSlotSelect = (slotInfo) => {
  //   const selectedDateStart = new Date(slotInfo.start);
  //   setSelectedDateStart(selectedDateStart);
  //   const today = new Date();
  //   const isPast = selectedDateStart < today;
  //   if(isPast) return;
  //   setShowModal(true);
  // };
  const handleSlotSelect = (slotInfo) => {
    const startDate = new Date(slotInfo.start);
  
    // Adjust for timezone offset
    const offset = startDate.getTimezoneOffset();
    const localStartDate = new Date(startDate.getTime() - offset * 60 * 1000);
  
    const formattedStartDate = localStartDate.toISOString().substr(0, 16);
  
    const endDate = new Date(localStartDate.getTime() + 2 * 60 * 60 * 1000);
    const formattedEndDate = endDate.toISOString().substr(0, 16);

    setSelectedDateStart(formattedStartDate)
    setSelectedDateEnd(formattedEndDate)
    const today = new Date();
    const isPast = localStartDate < today;
    if (isPast) return;
    setShowModal(true);
  };

  async function fetchData() {
    let tutorID = tutorId ? tutorId : tid;
    if(!tutorID)  tutorID = localStorage.getItem('selectedTutorId');
    
    try {
      const response = await fetch(`${API_BASE_URL}/session/${tutorID}`, {
        // headers: {
        //   "x-access-token": currentUser.token,
        // },
      });
      const sessions = await response.json();
      // const eventsWithDates = sessions.data.map((event) => ({
      //   ...event,
      //   start: new Date(event.start),
      //   end: new Date(event.end),
      // }));
      // setEvents([events, ...eventsWithDates]);

       // Get the current date and time
       const now = new Date();
       const startOfToday = new Date(now.setHours(0, 0, 0, 0));
   
       const filteredEvents = sessions.data.filter(event => {
         if (event.isBooked === "y") {
           return true; 
         }
         const eventEnd = new Date(event.end);
         return eventEnd > startOfToday;
       });
   
       const eventsWithDates = filteredEvents.map(event => ({
         ...event,
         start: new Date(event.start),
         end: new Date(event.end),
       }));
   
       setEvents(eventsWithDates); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (currentUser.roleName !== "STUDENT") {
      return;
    }   

    if (isEmail && !showRequestActionModal) {
      if(currentUser?.roleName === 'TUTOR') localStorage.setItem("selectedButton", 'calendar');
      else localStorage.setItem("selectedButton", ''); 
      setShowRequestActionModal(true);
    }
  }, [isEmail, showRequestActionModal]);

  useEffect(() => {
    if (sessionRequestFormData && sessionRequestFormData?.isRequestModelOpen) {
      setShowModal(true)
    }

   async function getTutor() {
      let id;
      if(!tutorId)  id = localStorage.getItem('selectedTutorId');
      else id = tutorId;
      const response = await fetch(`${API_BASE_URL}/user/${id}`, {
        headers: {
          'x-access-token': currentUser.token 
        }
      });
      const user = await response.json();
      setTutor(user.data)

      console.log(user);
      

      const minTimeString = user?.data?.tutorSettings?.earliestStartTime;   
      const maxTimeString = user?.data?.tutorSettings?.latestFinishTime; 
      
      // Convert to Date objects
      setMinTime(convertTimeStringToDate(minTimeString));
      setmaxTime(convertTimeStringToDate(maxTimeString));
      
   }

    fetchData();
    getTutor();
  }, []);

  const CustomEvent = ({ event }) => {
    return (
      <Box marginLeft={{ base: "0px", xl: "0px" }}>
        <Box
          display="block"
          fontSize="14px"
          fontStyle="normal"
          textAlign="left"
        >
           <Box fontWeight="800">
           {view === 'month' 
              ? (event?.serviceId?.name?.toLowerCase() === 'one to one session'
                  ? 
                    <>
                      {`${formatTime(event?.start)} ${event?.totalTime}`}
                      <Box display="block">{event?.bookedBy?.displayName}</Box>
                    </>
                  : (
                      <>
                        {`${formatTime(event?.start)} ${event?.totalTime}`}
                        <Box>{event?.serviceId?.name}</Box>
                        <Box display="block">{event?.bookedBy?.displayName}</Box>
                      </>
                    )
                )
              : (event?.serviceId?.name?.toLowerCase() === 'one to one session') 
                  ? '' 
                  : <>
                      {`${formatTime(event?.start)} ${event?.totalTime}`}
                      <Box display="block">{event?.serviceId?.name}</Box>
                      <Box display="block">{event?.bookedBy?.displayName}</Box>
                    </>
            }
            </Box>
        </Box>
      </Box>
    );
  };


  const convertTimeStringToDate = (timeString) => {
    const [hours, minutes] = timeString?.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  return (
    <>
      {/* <CalenderHeader /> */}
      <Box bg="grey" color="white" rounded={"10px"}>
        <Calendar
          localizer={localizer}
          min={minTime} // Set the minimum time for the calendar view
          max={maxTime} // Set the maximum time for the calendar view
          events={events}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetter}
          startAccessor={(event) => {
            return new Date(event.start);
          }}
          endAccessor={(event) => {
            return new Date(event.end);
          }}
          view={view}
          style={{ height: `${window.innerHeight}px` }}
          views={["month", "week", "day"]}
          onView={handleViewChange}
          onSelectSlot={handleSlotSelect}
          onSelectEvent={handleEventSelect}
          selectable={true}
          components={{
            toolbar: (props) => <CustomToolbar {...props} view={view} profile={tutor.profilePic} displayName={tutor.displayName} />,
            event: CustomEvent,
            week: {
              header: CustomDayHeader,
            },
          }}
        />
      </Box>
      {showModal && (
        <RequestForm onFormClose={handleCloseModal} isCalenderRequest={true} startDate={selectedDateStart} endDate={selectedDateEnd} tutorId={tutorId} />
      )}
       {isEditBooking && (
        <EditBooking onFormClose={handleFormClose} tutorId={selectedBooking?.userId} selectedBooking={selectedBooking} fetchBookings={fetchData} bookingId={selectedBooking._id} onDeleteSuccess={fetchData}/>
      )}
      { showRequestActionModal && (
        <RequestActionModal isOpen={showRequestActionModal} confirm={isConfirm} modify={isModify} cancel={isCancel} requestID={requestID} onClose={handleCloseModal}/>
      )}
      { showBookSessionForm && (
        <BookSessionFormTwo handleCloseBookingForm={handleBookingClose} serviceId={selectedEvent?.serviceId?._id} sessionId={selectedEvent?._id}/>
      )}
      { showChooseServiceForm && (
        <BookSessionFormOne handleCloseForm={handleCloseChooseServiceForm} sessionId={chooseServiceData.sessionId} tutorId={chooseServiceData.tutorId} sessionDate={chooseServiceData.sessionDate}/>
      )}
    </>
  );
};

export default StdCalendar;
