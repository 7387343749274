import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { LuPlus } from "react-icons/lu";

export default function MyRequestHeader({ title, des, onCreateRequest }) {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  function handleRequest() {
    onCreateRequest(true);
  }

  return (
    <>
      <Box
        w="full"
        px="24px"
        bg={{ base: "#1D1D1D", md: "#1D1D1D" }}
        color="white"
        py={{ base: "0px", md: "34px" }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="5px"
          flexDir="row"
          display={{ base: "none", md: "flex" }}
          position="relative"
        >
          <Box
            margin="auto"
            textAlign="center"
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
          >
            <Heading color="white" fontWeight="600" fontSize="28px">
              My Session Requests
            </Heading>
            <Text color="white" fontWeight="500" fontSize="14px">
              View and manage your Session Requests
            </Text>
          </Box>
            <Box marginLeft="auto">
              <Button
                onClick={handleRequest}
                rightIcon={<LuPlus />}
                bg="#0033ff"
                _hover={{
                  bg: "#0033ff",
                  color: "white",
                  boxShadow: "0 0 3px black",
                }}
                color="#F2F2F2"
                rounded="5px"
                py="12px"
                px="24px"
                w={{ base: "100%", xl: "auto" }}
                fontSize="14px"
                fontWeight="600"
              >
                Create a Request
              </Button>
            </Box>
        </Flex>
      </Box>

      <Link to="/">
        <Box
          display={{ base: "block", md: "none" }}
          w="full"
          px="24px"
          borderBottomWidth="1px"
          bg="dull_black"
          boxShadow="sm"
          color="white"
          py="6px"
        >
          back
        </Box>
      </Link>
    </>
  );
}
