import { 
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Box, 
  Flex, 
  Image, 
  Button, 
  Text, 
  Link,
  useToast 
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL, SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../apiPaths";
import RequestForm from "../Forms/MyRequest/RequestForm.jsx";

const RequestActionModal = ({
  isOpen,
  onClose,
  requestID,
  senderName,
  serviceName,
  date,
  rateType,
  rate,
  serviceImage,
  senderImage,
  confirm,
  modify,
  cancel
}) => {
  const toast = useToast();
  const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false); 
  const [isMainModalOpen, setIsMainModalOpen] = useState(isOpen);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [request, setRequest] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentUser = JSON.parse(localStorage.getItem("currentUser")); 
  const navigate = useNavigate();

  const onCancelConfirmModal = () => {
    setIsConfirmBtnClicked(false); 
  }

  const onCancelMainModal = () => {
    setIsMainModalOpen(false); 
    onClose(); 
    if(currentUser.roleName === "TUTOR") { 
      navigate(`/calendar`);
    } else {
      navigate(`/calendar/tutor/${request.tutor._id}`);
    }
  }

  useEffect(() => {
    async function fetchRequest() {
      const response = await fetch(`${API_BASE_URL}/session-request/get-by-id/${requestID}`, {
        headers: {
          'x-access-token': currentUser.token 
        }
      });
      const request = await response.json();

      if (!response.ok) {
        toast({
          title: "Session request is already booked, deleted or expired.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        
        setIsConfirmBtnClicked(false); 
        onCancelMainModal();
      }

      console.log(request.data);
      

      setRequest(request.data)
    }

    if(confirm) {
      setIsConfirmBtnClicked(true);      
    }
  

    fetchRequest();
  }, []);

  const handleBooking = async () => {
    const startDate = new Date(request.start);
    const endDate = new Date(request.end);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    const hours = Math.floor(differenceInHours);
    const minutes = Math.round((differenceInHours - hours) * 60);
    const formattedTime =
      `${hours} ${hours === 1 ? "hr" : "hrs"}` +
      (minutes !== 0 ? ` ${minutes} min` : "");

    const payload = {
      start: request.start,
      end: request.end,
      location: request.sessionLocation,
      service: request.service._id,
      repeat: "No Repeat",
      sessionOrBreak: "session",
      totalTime: formattedTime,
      id: request._id
    }
    
    if(currentUser.roleName === "TUTOR") payload.student = request.user._id;
    else payload.creative = request.tutor._id;    
    
    try {
      const response = await fetch(`${API_BASE_URL}/session-request/booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        toast({
          title: "Request failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsConfirmBtnClicked(false); 
        throw new Error("Network response was not ok");
      } else {

        toast({
          title: "Session request booked successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsConfirmBtnClicked(false); 
        onCancelMainModal();
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    } finally {
    }
  }

  const handleModifyRequest = async () => {
    setShowRequestForm(true);
  }

  const handleRejectRequest = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/session-request/${request._id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
      });

      if (!response.ok) {
        toast({
          title: "Error deleting session request.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        toast({
          title: "Session request rejected successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onCancelMainModal();
      }
    } catch (error) {
      console.error("Error rejecting session request:", error);
    }
  };

  function handleFormClose(val) {
    setShowRequestForm(val);
  }


  return (
    <>
    <Modal isOpen={isMainModalOpen} onClose={onCancelMainModal}  isCentered size="2xl">
      <ModalOverlay />
      <ModalContent
        bg="white"
        color="black"
        position="relative"
        py={{ base: "10px", xl: "40px" }}
      >
        <ModalCloseButton position="absolute" top="10px" left="10px" />
        <form>
          <ModalBody px={{ base: "20px", xl: "40px" }} pb={6}>
            <Box textAlign="center" width="" margin="auto">
              <Text as="h1" fontSize="3xl" fontWeight="bold">LOGO</Text>
              <Text>
                {
                  currentUser.roleName === "TUTOR" ? 
                  `${request?.user?.firstName} ${request?.user?.lastName}` : 
                  `${request?.tutor?.firstName} ${request?.tutor?.lastName} `
                } 
                would like a <b>{request?.service?.name}</b> session with you
              </Text>
              <Text>{new Date(request?.start).toLocaleString('en-US', { 
                  year: 'numeric', 
                  month: 'long', 
                  day: 'numeric', 
                  hour: 'numeric', 
                  minute: 'numeric', 
                  hour12: true 
                })}</Text>
              <Text>{request?.sessionLocation}</Text>
              <Text>
                {request?.service?.rate} {request?.service?.rateType}
              </Text>

              <Flex justifyContent="space-between" mb="30px" mt="20px">
                <Box textAlign="left" width="50%">
                  <Image
                    src={`${SERVICE_IMAGE_URL}/${request?.service?.image}`}
                    alt="Service Image"
                    width="200px"
                    height="200px"
                    crossOrigin="annonymous"
                  />
                  <Text fontSize="lg">{request?.service?.name }</Text>
                </Box>
                <Box textAlign="right">
                  <Image
                     src={
                      currentUser.roleName === "TUTOR"
                        ? `${USER_IMAGE_URL}/${request?.user?.profilePic}` 
                        : `${USER_IMAGE_URL}/${request?.tutor?.profilePic}` 
                    }
                    alt="Service Image"
                    width="200px"
                    height="200px"
                    style={{ borderRadius: "50%" }} 
                    crossOrigin="annonymous"
                  />
                  <Text fontSize="lg">
                    {
                      currentUser.roleName === "TUTOR" ? 
                      `${request?.user?.firstName} ${request?.user?.lastName}` : 
                      `${request?.tutor?.firstName} ${request?.tutor?.lastName} `
                    } 
                  </Text>
                </Box>
              </Flex>

              <Link href="#">
                <Button
                  bg="black"
                  color="white"
                  width="100%"
                  borderRadius="20px"
                  py="20px"
                  fontWeight="bold"
                  mb="10px"
                  _hover={{ bg: "gray.700" }}
                  onClick={() => setIsConfirmBtnClicked(true)}
                >
                  Confirm
                </Button>
              </Link>

              <Link href="#">
                <Button
                  bg="transparent"
                  color="black"
                  border="1px solid black"
                  width="100%"
                  borderRadius="20px"
                  py="8px"
                  fontWeight="bold"
                  mb="10px"
                  _hover={{ bg: "gray.200" }}
                  onClick={() => handleModifyRequest()}
                >
                  Modify
                </Button>
              </Link>

              <Link href="#">
                <Button
                  bg="#E1526C"
                  color="white"
                  width="100%"
                  borderRadius="20px"
                  py="10px"
                  fontWeight="bold"
                  mb="10px"
                  _hover={{ bg: "#c13e59" }}
                  onClick={() => handleRejectRequest()}
                >
                  Reject
                </Button>
              </Link>
            </Box>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
    
      <Modal
      isOpen={isConfirmBtnClicked} 
      onClose={onCancelConfirmModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg={"#fff"} color="#000">
        <ModalBody>
        <Text mb={4} mt={4} align="center">
          {confirm && "Would you like to confirm this session?"}
          {modify && "Would you like to modify this session?"}
          {cancel && "Would you like to reject this session?"}
        </Text>
          <Flex direction="row" align="center" justify="space-between" px="70px" mb="3">
              <Button
                color="black"
                fontSize="15px"
                fontWeight="600"
                onClick={handleBooking}
              >
                YES
              </Button>
              <Button
                onClick={() => onCancelConfirmModal()}
                color="red"
                fontSize="15px"
                fontWeight="600">
                CANCEL
              </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
    {showRequestForm && (
        <RequestForm onFormClose={handleFormClose} requestId={request._id} tutorId={currentUser.id} isCalenderRequest={true}/>
     )}
   </>
  );
};

export default RequestActionModal;
