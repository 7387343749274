import React, { useEffect, useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  Box,
  Textarea,
  FormLabel,
  Image,
  Flex,
  Select,
  FormErrorMessage,
  Text,
  useToast,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";

import { Controller, useForm } from "react-hook-form";
import BookSessionFormThree from "./BookSessionFormThree";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BookSessionFormHeaderTwo from "../../Headers/BookSessionFormHeaderTwo";
import { Link, useLocation, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../apiPaths";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import PaymentModel from "../../payment/PaymentModel";
import MyCalendar from "../../Calender/StdCalendar/Calender";

const signupSchema = yup.object().shape({});
const BookSessionFormTwo = ({ serviceId, sessionId, handleCloseBookingForm }) => {
  const toast = useToast();
  const location = useLocation();
  let { id } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [service, setService] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showBooked, setShowBooked] = useState(false);
  const [open, setOpen] = useState(false);
  const { pathname } = location;
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: {},
  });

  const sessionLocationOptions = [
    { value: "Intermidiate", label: "Intermidiate" },
    { value: "Advance", label: "Advance" },
    { value: "Expert", label: "Expert" },
  ];

  const watchNotes = watch("notes", "");
  const watchNotes1 = watch("notes1", "");

  const watchDiscription = watch("discription", "");
  const watchAnswer = watch("answer", "");

  const searchParams = new URLSearchParams(location.search);

  const handleCloseBooking = () => {
    handleCloseBookingForm(false);
  };

  useEffect(() => {    
    async function fetchData() {
      try {
        const response = await fetch(
          `${API_BASE_URL}/service-builder/get/${sessionId}`,
          {
            headers: {
              "x-access-token": currentUser.token,
            },
          }
        );
        const service = await response.json();
        setService(service);
        if (response) {
          setOpen(true);
        }
        setService(service?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [id, currentUser.token]);

  const questions = Array.isArray(service?.serviceId?.questionsAnswers) 
    ? service?.serviceId?.questionsAnswers
    : [];
  const { isOpen, onClose } = useDisclosure();
  const startDate = new Date(service?.start);
  const endDate = new Date(service?.end);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = startDate.toLocaleDateString("en-US", options);
  const startTime = startDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTime = endDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedTime = `${startTime} - ${endTime}`;
  const onSubmit = async (data) => {
    setIsLoading(true);
    data.sessionId = service?._id;
    data.serviceId = service?.serviceId?._id;
    data.tutorId = service?.userId;

    try {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const response = await fetch(`${API_BASE_URL}/booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        toast({
          title: "Booking failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        toast({
          title: "Session booked successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setShowBooked(true);
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (showBooked) {
    return (
      <>
        <PaymentModel setShowBooked1={setShowBooked} data={service} />
      </>
    );
  }

  return (
    <Box>
      <MyCalendar />
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        isCentered
        onClose={onClose}
        size={{
          base: "md",
          sm: "",
          md: "xl",
          lg: "2xl",
          xl: "2xl",
          "2xl": "3xl",
        }}>
        <ModalContent bg={"#fff"} height={{ base: "auto", sm: "auto" }}>
          <Box as="form" onSubmit={handleSubmit(onSubmit)} width={"100%"} p={4}>
            <Flex justifyContent={"center"} position={"relative"}>
                <Box
                  position={"absolute"}
                  left={3}
                  cursor="pointer"
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={handleCloseBooking}>
                  <Box color={"#000"} mt={2}>
                    <ChevronLeftIcon fontSize={"30px"} fontWeight={"400"} />
                  </Box>

                  <Box>
                    <Text mt={2} color="#000" fontWeight="500" fontSize="16px">
                      Back
                    </Text>
                  </Box>
                </Box>
              <Box>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  mt={{ base: "40px", sm: "auto" }}>
                  <Text
                    fontSize={{ base: "18px", xl: "24px" }}
                    color="#000"
                    fontWeight="600">
                    {service?.serviceId?.name}
                  </Text>

                  <Text
                    display={{ base: "none", sm: "block" }}
                    fontSize={{ base: "12px", xl: "14px" }}
                    color="#000"
                    my="5px">
                    {formattedDate}, {formattedTime}
                  </Text>

                  {/* <Box
                    display={{ base: "block", sm: "none" }}
                    textAlign={"center"}>
                    <Text
                      fontSize={{ base: "12px", xl: "14px" }}
                      color="#000"
                      my="5px">
                      {formattedDate}
                    </Text>
                    <Text
                      fontSize={{ base: "12px", xl: "14px" }}
                      color="#000"
                      my="5px">
                      {formattedTime}
                    </Text>
                  </Box> */}

                  <Text color={"#000"} fontSize="14px" fontWeight="500">
                    £{service?.serviceId?.rate ? service?.serviceId?.rate : 0}
                    .00 p/h
                  </Text>
                </Flex>
              </Box>
            </Flex>

            <VStack spacing={{ base: "15px", md: "20px" }} w="100%" p={4}>
              {questions.map((questionStr, index) => {
                // Parse the question before using it
                const question = JSON.parse(questionStr);
                return (
                  <div key={index} style={{ width: "100%" }}>
                    {/* Looping through each question */}
                    {question.map((q, idx) => (
                      <FormControl key={idx} mt="4" isInvalid={errors[`answer_${index}`]}>
                        <FormLabel color="#000">{q.question}</FormLabel>

                        {/* Displaying multiple choice dropdown if question type is "multipleChoice" */}
                        {q.answerType === "multipleChoice" ? (
                          <Controller
                            control={control}
                            name={`answer_${index}_${idx}`}
                            rules={{ required: "Please select an answer" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                color="#000"
                                borderWidth="1px"
                                borderColor="gray.300"
                                _hover={{
                                  borderWidth: "1px",
                                  borderColor: "gray.300",
                                }}
                                value={field.value || ""} // Default to an empty string for "Choose answer"
                                required
                              >
                                <option
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                  value=""
                                >
                                  Choose answer
                                </option>
                                {(q.options.split(",") || []).map((option, optionIndex) => (
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                    key={optionIndex}
                                    value={option}
                                  >
                                    {option}
                                  </option>
                                ))}
                              </Select>
                            )}
                          />
                        ) : (
                          // Displaying text area for other question types
                          <Controller
                            control={control}
                            name={`answer_${index}_${idx}`}
                            render={({ field }) => (
                              <Textarea
                                {...field}
                                borderColor={errors[`answer_${index}`] ? "#E1526C" : "border"}
                                rounded="md"
                                _hover={{
                                  borderColor: "#616161",
                                }}
                                type="text"
                                color="black"
                                placeholder="Answer"
                                _placeholder={{
                                  color: "text",
                                  fontSize: { base: "12px", md: "14px" },
                                }}
                                required
                              />
                            )}
                          />
                        )}
                        <FormErrorMessage>
                          {errors[`answer_${index}`]?.message}
                        </FormErrorMessage>
                      </FormControl>
                    ))}
                  </div>
                );
              })}

              <FormControl>
                <FormLabel fontSize="14px" fontWeight={500} color="#000">
                  Describe the feedback you are looking for
                </FormLabel>
                <Controller
                  control={control}
                  name="notes1"
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      borderColor={errors.notes1 ? "#E1526C" : "border"}
                      rounded="md"
                      color="#000"
                      _hover={{
                        borderColor: "#616161",
                      }}
                      type="text"
                      placeholder="Answer"
                      _placeholder={{
                        color: "#000",
                        fontSize: { base: "12px", md: "14px" },
                      }}
                    />
                  )}
                />
                <FormErrorMessage color="#E1526C" fontSize="14px" mt="5px">
                  {errors.notes1 && <p>{errors.notes1?.message}</p>}
                </FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel fontSize="14px" fontWeight={500} color="#000">
                  Additional notes
                </FormLabel>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      color="#000"
                      borderColor={errors.notes ? "#E1526C" : "border"}
                      rounded="md"
                      _hover={{
                        borderColor: "#616161",
                      }}
                      type="text"
                      placeholder="Answer"
                      _placeholder={{
                        color: "#000",
                        fontSize: { base: "12px", md: "14px" },
                      }}
                    />
                  )}
                />
                <FormErrorMessage color="#E1526C" fontSize="14px" mt="5px">
                  {errors.notes && <p>{errors.notes?.message}</p>}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <VStack w="100%" mt={{ base: "15px", md: "30px" }} p={4}>
              <Button
                color="white"
                rounded="40px"
                w="100%"
                bg={"black"}
                _hover={{
                  bg:
                    !watchNotes ||
                    !watchDiscription ||
                    !watchAnswer ||
                    !watchNotes1
                      ? ""
                      : "black",
                }}
                py="12px"
                fontSize="14px"
                fontWeight="600"
                type="submit"
                isLoading={isLoading}>
                Book session
              </Button>
            </VStack>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BookSessionFormTwo;
