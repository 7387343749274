import React, { useState } from "react";
import SettingsHeader from "../Components/Headers/SettingsHeader";
import {
  FormControl,
  Input,
  Heading,
  VStack,
  FormLabel,
  Stack,
  Center,
  Switch,
  InputGroup,
  Button,
  Box,
  useToast,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_BASE_URL } from "../apiPaths";

const settingsSchema = yup.object().shape({
  earliestStartTime: yup.string().required("Earliest start time is required"),
  latestFinishTime: yup.string().required("Latest finish time is required"),
  flatRate: yup.string().required("Flat rate is required"),
});

function Setting() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const toast = useToast();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(settingsSchema),
    defaultValues: {
      flatRate: currentUser?.flatRate || "",
    },
  });

  // Watch for flatRate and ensure it's updated correctly
  const flatRate = watch("flatRate");

  const handleSwitchChange = (e) => {
    setIsSwitchOn(e.target.checked);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/tutor-setting/rate/${currentUser.id}`,
        {
          method: "PUT",
          headers: {
            "x-access-token": currentUser.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        toast({
          title: "Failed to update flat rate.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        throw new Error("Network response was not ok");
      } else {
        const updatedUser = {
          ...currentUser,
          flatRate: Number(data.flatRate),
          settings: {
            earliestStartTime: data.earliestStartTime,
            latestFinishTime: data.latestFinishTime,
          },
        };
        localStorage.setItem("currentUser", JSON.stringify(updatedUser));
        toast({
          title: "Settings updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SettingsHeader />
      <Center mt={{ base: "20px", xl: "25px" }}>
        <Stack
          minW={{ base: "100%", md: "500px" }}
          width="40%"
          spacing={{ base: 6, xl: 12 }}
        >
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg={useColorModeValue("white", "black")}
            color="white"
            position="relative"
            borderTopRadius={{ base: "16px", md: "none" }}
            rounded={{ base: "30px", md: "30px" }}
            px={{ base: "15px", md: "24px" }}
            py={{ base: "40px", md: "32px" }}
          >
            <VStack>
              {/* <Heading size="lg" fontWeight="600">
                                Calendar Settings
                            </Heading> */}
            </VStack>
            <FormControl isInvalid={errors.earliestStartTime}>
              <FormLabel fontSize={{ base: "", md: "1.5rem" }}>
                Your Calendar Day Starts From
              </FormLabel>

              <Input
                type="time"
                border="none"
                color="#0033ff"
                fontWeight="800"
                fontSize={{ base: "1.5rem", md: "3.5rem" }}
                height="unset"
                // borderWidth="1px"
                // borderColor="gray.300"
                {...register("earliestStartTime")}
                defaultValue={
                  currentUser?.settings?.earliestStartTime || "09:00"
                }
                _hover={
                  {
                    // borderWidth: "1px",
                    // borderColor: "gray.300",
                  }
                }
              />
              <FormErrorMessage>
                {errors.earliestStartTime?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.latestFinishTime}>
              <FormLabel fontSize={{ base: "", md: "1.5rem" }}>
                Your Calendar Day Finishes At
              </FormLabel>
              <Input
                type="time"
                border="none"
                color="#0033ff"
                fontWeight="800"
                height="unset"
                fontSize={{ base: "1.5rem", md: "3.5rem" }}
                {...register("latestFinishTime")}
                defaultValue={
                  currentUser?.settings?.latestFinishTime || "17:00"
                }
                // _hover={{
                //     borderWidth: "1px",
                //     borderColor: "gray.300",
                // }}
              />
              <FormErrorMessage>
                {errors.latestFinishTime?.message}
              </FormErrorMessage>
            </FormControl>

            <VStack mt={5}>
              {/* <Heading size="lg" fontWeight="600">
                                Rate Settings
                            </Heading> */}
            </VStack>

            <VStack spacing={{ base: "15px", md: "15px" }} w="100%">
              <FormControl>
                <FormLabel fontSize={{ base: "", md: "1.5rem" }}>
                  Flat Rate
                </FormLabel>
                <Switch
                  id="rate-setting"
                  size="lg"
                  onChange={handleSwitchChange}
                  // disabled='true'
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      color: "grey",
                      "&.Mui-checked": {
                        color: "blue ",
                      },
                    },
                  }}
                />
              </FormControl>
              {!isSwitchOn && (
                <FormControl mt="4" id="flatRate">
                  <FormLabel fontSize={{ base: "", md: "1.5rem" }}>
                    Your flat rate per hour
                  </FormLabel>
                  <InputGroup>
                    <Box
                      as="span"
                      my="auto"
                      fontSize={{ base: "1.5rem", md: "2rem" }}
                      className="euro-sign"
                    >
                      £
                    </Box>
                    <Input
                      className="rate-per-hour"
                      type="text"
                      border="none"
                      color="#0033ff"
                      fontWeight="800"
                      height="unset"
                      padding="0"
                      fontSize={{ base: "1.5rem", md: "3.5rem" }}
                      // borderWidth='1px'
                      // borderColor='gray.300'
                      // _hover={{
                      //     borderWidth: '1px',
                      //     borderColor: 'gray.300',
                      // }}
                      defaultValue={currentUser?.flatRate}
                      {...register("flatRate")} 
                    />
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.flatRate && <p>{errors.flatRate.message}</p>}
                    </Box>
                  </InputGroup>
                </FormControl>
              )}

              {/* {isSwitchOn && (
                                <>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions under 1 hour</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 1 hour and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 2 hours and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 3 hours and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 4 hours and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </>
                            )} */}
            </VStack>
            <VStack w="100%" mt={{ base: "15px", md: "30px" }}>
                            <Button
                                color="white"
                                rounded="40px"
                                w="100%"
                                bg="#0033ff"
                                _hover={{
                                    bg: "#0033ff"
                                }}
                                py="12px"
                                fontSize="16px"
                                fontWeight="600"
                                type="submit"
                                isLoading={isLoading}
                                isDisabled={!flatRate}
                            >
                                Save
                            </Button>

                        </VStack>
          </VStack>
        </Stack>
      </Center>
    </>
  );
}

export default Setting;
