import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../apiPaths";
import CreativeItem from "../Components/FromSidebar/Student/CreativesList/CreativeItem";
import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  Text,
  Heading,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assests/images/logo.png";
import backgroundImage from "../assests/images/home_bg.jpg";

export default function Home() {
  const [userData, setUserData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const navigate = useNavigate();

  async function fetchData(q) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/user/tutors/get?q=${q}`,
        {}
      );
      const users = await response.json();
      setUserData(users.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.roleName == "TUTOR") {
      localStorage.setItem("selectedButton", "calendar");
      navigate("/calendar");
    }
    if (currentUser && currentUser.roleName == "STUDENT") {
      localStorage.setItem("selectedButton", "dashboard");
      navigate("/tutor-search");
    }
    const element = document.querySelector("body");
    const input = document.querySelector("body .css-1ngklx");
    if (element) {
      element.style.backgroundColor = "#F2F2F2";
      element.style.color = "black";
    }
  }, []);

  const handleChange = (event) => {
    setIsFetch(true);
    fetchData(event.target.value);
  };

  return (
    <Box
    // height="100vh"
    // maxHeight="auto"
    // background={`url(${backgroundImage})`}
    // backgroundSize="cover"
    // backgroundPosition="center"
    // backgroundAttachment="fixed"
    >
      <Flex p={"10px"} position="fixed" right="0">
        <Box marginLeft="auto" padding="20px">
          {!currentUser && (
            <Box>
              <Link to={`/login`}>
                <Button
                  bg="white"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black",
                  }}
                  color="blue"
                  rounded="40px"
                  py="10px"
                  px="20px"
                  mx="10px"
                  border="1px solid  #2F2F2F"
                  fontSize="14px"
                  fontWeight="600"
                >
                  LOGIN
                </Button>
              </Link>

              <Link to={`/register`}>
                <Button
                  bg="blue"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black",
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  py="10px"
                  px="20px"
                  mx="10px"
                  // border="1px solid  #fff"
                  fontSize="14px"
                  fontWeight="600"
                >
                  REGISTER
                </Button>
              </Link>
            </Box>
          )}
          {currentUser && (
            <Button
              bg="white"
              _hover={{
                bg: "black",
                color: "white",
              }}
              color="blue"
              rounded="40px"
              py="10px"
              px="20px"
              mx="10px"
              border="1px solid  #fff"
              fontSize="14px"
              fontWeight="600"
              onClick={() => {
                window.localStorage.removeItem("currentUser");
                localStorage.removeItem("sessionRequestFormData");
                localStorage.removeItem("view");
                navigate("/login");
              }}
            >
              LOG OUT
            </Button>
          )}
        </Box>
      </Flex>

      <Box
        height="100vh"
        width={{ base: "100%", md: "30%" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box py="auto" textAlign="center" color="#fff">
          <Heading fontSize={{ base: "12px", md: "20px" }} fontWeight="400">
            START YOUR CREATIVE JOURNEY WITH
          </Heading>
          <Heading
            fontSize={{ base: "6rem", md: "8rem" }}
            fontWeight="900"
            letterSpacing="0"
          >
            BASE
          </Heading>

          <Box>
            <InputGroup
              borderRadius={5}
              size="sm"
              width="90%"
              mx="auto"
              mb={10}
            >
              <Input
                type="text"
                placeholder="Search"
                onChange={handleChange}
                background="#fff"
                border="1px solid black"
                borderWidth="2px"
                borderColor="gray.300"
                padding="20px 10px"
                color="#000"
                _hover={{
                  borderWidth: "2px",
                  borderColor: "gray.300",
                }}
                sx={{ "::placeholder": { color: "#000" } }}
              />
              <InputRightElement
                pointerEvents="none"
                padding="20px 20px"
                fontSize="18px"
                children={<Search2Icon color="#000" />}
              />
            </InputGroup>
          </Box>
        </Box>

       
      </Box>
      {userData?.map((user) => (
          <Box
            style={{
              marginBottom: "10px",
              color: "#000",
            }}
          >
            <CreativeItem key={user._id} data={user} />
          </Box>
        ))}
        {userData.length < 1 && isFetch && (
          <Box textAlign="center">Records not found.</Box>
        )}
    </Box>
  );
}
