import { useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
  Box,
  useToast
} from "@chakra-ui/react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../apiPaths";
import bgImage from "../assests/images/login_bg.jpeg"
import Logo from "../assests/images/logo.png"

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  // const [succssfulLogin, setSuccssfulLogin] = useState(false);
  const sessionRequestFormData = JSON.parse(localStorage.getItem('sessionRequestFormData'));
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => setShow(!show);
  const users = [
    {
      email: "admin@admin.com",
      password: "admin123",
      role: "TUTOR",
    },
    {
      email: "user@user.com",
      password: "user123",
      role: "STUDENT",
    },
  ];
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm();
  const watchEmail = watch("email", "");
  const watchPassword = watch("password", "");

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!data.email) {
      setError("email", {
        type: "manual",
        message: "email not recognised",
      }); 
    }
    if (!data.password) {
      setError("password", {
        type: "manual",
        message: "password incorrect",
      });
    }
    // const matchedUser = users.find(
    //   (user) => user.email === data.email && user.password === data.password
    // );

    try{
      const response = await fetch(`${API_BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      const jsonResponse = await response.json();

      if (!response.ok) {
        toast({
          title:  `${jsonResponse.data.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error(jsonResponse.message);
      }
      
      localStorage.setItem('currentUser', JSON.stringify(jsonResponse.data));
      
      if(jsonResponse?.data?.roleName === 'TUTOR') localStorage.setItem("selectedButton", 'calendar');
      else localStorage.setItem("selectedButton", 'dashboard'); 
      toast({
        title: "Login success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      if(jsonResponse.data.roleName == "STUDENT") navigate("/tutor-search");
      else navigate("/calendar");

      if (sessionRequestFormData && sessionRequestFormData?.url) {
        navigate(sessionRequestFormData.url);
      } else {
        if(jsonResponse.data.roleName == "STUDENT") navigate("/tutor-search");
        else navigate("/calendar");
      }
    } catch(error){
      console.error('There has been a problem with your fetch operation:', error);
    } finally {
      setIsLoading(false);
    }
  }

    // if (matchedUser) {
    //   const userToStore = {
    //     email: matchedUser.email,
    //     role: matchedUser.role,
    //   };
    //   localStorage.setItem("currentUser", JSON.stringify(userToStore));
    //   navigate("/");
    // } else {
    //   setError("email", {
    //     type: "manual",
    //     message: "email or password is incorrect",
    //   });
    //   setError("password", {
    //     type: "manual",
    //     message: "email or password is incorrect",
    //   });
    // }
    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_BASE_URL}/login`,
    //     {
    //       email: data.email,
    //       password: data.password,
    //     }
    //   );
    //   console.log(register?.data?.token);
    //   if (response?.data?.user) {
    //     console.log(response);
    //     navigate("/profile", { state: { token: response?.data?.token } });
    //   }
    // } catch (e) {
    //   console.log(e);
    //   if (e?.response?.data?.errors) {
    //     let errors = e?.response?.data?.errors;
    //     if (errors.email.length > 0) {
    //       setError("email", {
    //         type: "manual",
    //         message: errors.email,
    //       });
    //     }
    //     if (errors.password.length > 0) {
    //       setError("password", {
    //         type: "manual",
    //         message: errors.password,
    //       });
    //     }
    //   }
    // }
    
    // setSuccssfulLogin(true);
    // navigate('/profile')
  

  const isLoginFormValid = () => {
    return !watchEmail || watchPassword.length < 6;
  };

  return (
    <Box position="relative" 
    background={`url(${bgImage})`}
    backgroundSize="cover"
    backgroundRepeat="no-repeat" >
      <Box
        display={{ base: "none", md: "block" }}
        position="absolute"
        top={{base:"0" ,md:"2%"}}
        left={{base:"0" ,md:"2%"}}
        fontSize="48px"
        color="#fff"
        fontWeight="600"
      >
      <Heading fontSize={{ base: "6rem", md: "4rem" }} fontWeight="900" letterSpacing="0">BASE</Heading>
      </Box>

      <Center h="100vh" alignItems={["end", "end", "center"]}>
        <Stack minW={{ base: "100%", md: "640px" }} spacing={12} marginLeft="auto">
          <Center
            display={{ base: "flex", md: "none" }}
            fontSize="24px"
            color="#fff"
            fontWeight="600"
          >
        <Heading fontSize={{ base: "2rem", md: "4rem" }} fontWeight="900" letterSpacing="0">BASE</Heading>

          </Center>

          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg={{ base: "rgba(0, 0, 0, 0.5)", md: "transparent" }}
            width={{ base: "90%", md: "100%" }} // Adjust width for small screens
            maxWidth={{ base: "400px", md: "none" }}
            mx="auto"
            // bg="#1D1D1D"
            borderTopRadius={{ base: "0", md: "none" }}
            rounded={{ base: "", md: "lg" }}
            px={{ base: "10px", md: "140px" }}
            py={{ base: "10px", md: "60px" }}
          >
            <Stack align="center" mb="30px">
              <Heading
                fontSize={{ base: "18px", md: "30px" }}
                fontWeight="900"
                color="white"
              >
                Sign In
              </Heading>
            </Stack>

            <VStack spacing={4} w="100%">
              <FormControl id="email">
                <Input
                  // bg="#2F2F2F"
                  borderRadius="0"
                  borderColor={errors.email ? "#E1526C" : "#fff"}
                  // rounded="md"
                  _hover={{
                    borderColor: "#fff",
                  }}
                  color="white"
                  type="email"
                  py="20px"
                  placeholder="email address"
                  {...register("email")}
                  _placeholder={{
                    color: "#D2D2D2",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.email && <p>{errors.email.message}</p>}
                </Box>
              </FormControl>
              <FormControl id="password">
                <InputGroup size="md">
                  <Input
                    // bg="#2F2F2F"
                    borderRadius="0"
                    border="2px"
                    borderColor={errors.password ? "#E1526C" : "#fff"}
                    color="white"
                    py="20px"
                    // rounded="md"
                    _hover={{
                      borderColor: "#fff",
                    }}
                    type={show ? "text" : "password"}
                    placeholder="password"
                    {...register("password")}
                    _placeholder={{
                      color: "#D2D2D2",
                      fontSize: { base: "12px", md: "14px" },
                    }}
                  />
                  <InputRightElement>
                    <Box size="sm" rounded="md" onClick={handleClick}>
                      {!show ? (
                        <AiOutlineEyeInvisible size={20} color="#616161" />
                      ) : (
                        <AiOutlineEye size={20} color="#fff" />
                      )}
                    </Box>
                  </InputRightElement>
                </InputGroup>
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.password && <p>{errors.password.message}</p>}
                </Box>
              </FormControl>
            </VStack>
            <Stack direction="row" justify="end" w="100%" color="#fff">
              <Link color="#fff" fontSize={{ base: "12px", md: "14px" }}>
                Forgot password?
              </Link>
            </Stack>
            <VStack w="100%" mt="30px" direction="column" gap="15px">
              <Button
                bg={isLoginFormValid() ? "#0033ff9d" : "#0033ff"}
                _hover={{
                  bg: isLoginFormValid() ? "#0033ff9d" : "0033ff",
                }}
                color="white"
                borderRadius="0"
                border="1px solid #fff"
                // rounded="40px"
                w="100%"
                py="20px"
                fontSize="18px"
                fontWeight="600"
                type="submit"
                isDisabled={isLoginFormValid()}
                isLoading={isLoading}
              >
                Log in
              </Button>
              <Link to="/register" style={{ width: "100%" }}>
                <Button
                  variant="outline"
                  borderColor="#fff"
                  bg="#0033ff"
                  py="20px"
                  _hover={{
                    bg: "#0033ff",
                  }}
                  color="white"
                  // rounded="40px"
                  borderRadius="0"
                  w="100%"
                  fontSize="18px"
                  fontWeight="600"
                >
                  Create an account
                </Button>
              </Link>
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </Box>
  );
};

export default Login;
